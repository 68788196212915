import {
  FETCH_CHARACTERISTIC_VALUE,
  DELETE_CHARACTERISTIC_VALUE
} from "../../actions/Сharacteristic/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const characteristicValueDetailReducer = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case FETCH_CHARACTERISTIC_VALUE:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_CHARACTERISTIC_VALUE:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
