import {
  FETCH_ALL_RELATED_NAMES,
  FETCH_RELATED_NAME,
  DELETE_RELATED_NAME
} from "../../actions/RelatedNames/type";

const defaultDetailState = {
  loading: true,
  data: {}
};

const defaultListState = {
  loading: true,
  data: {
    count: 20,
    maxPage: 1,
    page: 1,
    data: []
  }
};

export const relatedNameDetailReducer = (
  state = defaultDetailState,
  action
) => {
  switch (action.type) {
    case FETCH_RELATED_NAME:
      return { ...state, data: action.payload, loading: false };
    case DELETE_RELATED_NAME:
      return { ...state, data: {} };
    default:
      return state;
  }
};

export const relatedNamesListReducer = (state = defaultListState, action) => {
  switch (action.type) {
    case FETCH_ALL_RELATED_NAMES:
      return { ...state, data: action.payload, loading: false };
    default:
      return state;
  }
};
