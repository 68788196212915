import React from "react";
import UserProvider from "Providers/UserProvider/UserProvider";

// eslint-disable-next-line react/display-name
const UserHOC = WrappedComponent => props =>
  (
    <UserProvider>
      <WrappedComponent {...props} />
    </UserProvider>
  );

export default UserHOC;
