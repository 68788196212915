import React, { createContext, useEffect, useState } from "react";
import { apiImages } from "../../Services/Api.images";

export const ImagesContext = createContext([]);

const ImagesContextProvider = ({ value, children }) => (
  <ImagesContext.Provider value={value}>{children}</ImagesContext.Provider>
);

function ImagesProvider(props) {
  const { children } = props;

  const [imagesToDelete, setImagesToDelete] = useState([]);

  const addImageToDelete = image =>
    setImagesToDelete(prevState => [...prevState, image]);

  const setEmptyImagesToDelete = () => setImagesToDelete([]);

  const deleteImages = async () => {
    const res = await Promise.allSettled(
      imagesToDelete.map(item =>
        apiImages.deleteImage(item.imagePath, item.imageUuid, item.imageName)
      )
    );
    setEmptyImagesToDelete();
    return res;
  };

  const value = {
    imagesToDelete,
    addImageToDelete,
    setEmptyImagesToDelete,
    deleteImages
  };

  return (
    <ImagesContextProvider value={value}>{children}</ImagesContextProvider>
  );
}

export default ImagesProvider;
