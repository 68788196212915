import deliveryStatuses from "./deliveryStatuses";
import orders from "./orders";
import orderStatuses from "./orderStatuses";
import paymentStatuses from "./paymentStatuses";
import statusMapping from "./statusMapping";

const urls = [
  ...deliveryStatuses,
  ...orders,
  ...orderStatuses,
  ...paymentStatuses,
  ...statusMapping
];

export default urls;
