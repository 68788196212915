import {
  FETCH_CHARACTERISTIC_KEY,
  DELETE_CHARACTERISTIC_KEY
} from "../../actions/Сharacteristic/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const characteristicKeyDetailReducer = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case FETCH_CHARACTERISTIC_KEY:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_CHARACTERISTIC_KEY:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
