import {
  FETCH_CATEGORY,
  DELETE_CATEGORY
} from "Stores/actions/CategoryDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const categoryDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_CATEGORY:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
