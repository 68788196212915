import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const BannersList = React.lazy(() =>
  import("Components/NewBanners/BannersList/BannersList")
);
const BannersUpdate = React.lazy(() =>
  import("Components/NewBanners/BannersUpdate/BannersUpdate")
);
const BannersCreate = React.lazy(() =>
  import("Components/NewBanners/BannersCreate/BannersCreate")
);

const urls = [
  {
    path: "/admin-banner",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BannersList />
      </Suspense>
    )
  },
  {
    path: "/admin-banner/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BannersCreate />
      </Suspense>
    )
  },
  {
    path: "/admin-banner/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BannersUpdate />
      </Suspense>
    )
  }
];

export default urls;
