import {
  FETCH_DISTRICT,
  DELETE_DISTRICT
} from "../../actions/DistrictsDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const districtsDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_DISTRICT:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_DISTRICT:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
