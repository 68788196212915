import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const PaymentStatusesList = React.lazy(() =>
  import(
    "Components/Orders/PaymentStatuses/PaymentStatusesList/PaymentStatusesList"
  )
);
const PaymentStatusesCreate = React.lazy(() =>
  import(
    "Components/Orders/PaymentStatuses/PaymentStatusesCreate/PaymentStatusesCreate"
  )
);
const PaymentStatusesUpdate = React.lazy(() =>
  import(
    "Components/Orders/PaymentStatuses/PaymentStatusesUpdate/PaymentStatusesUpdate"
  )
);

export const URL_NAME = "payment-statuses";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <PaymentStatusesList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <PaymentStatusesCreate />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <PaymentStatusesUpdate />
      </Suspense>
    )
  }
];

export default urls;
