import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";

const Images = React.lazy(() => import("../../Components/Images/Images"));

const urls = [
  {
    path: "/images",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Images />
      </Suspense>
    )
  }
];

export default urls;
