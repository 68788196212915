import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const DeliveryStatusesList = React.lazy(() =>
  import(
    "Components/Orders/DeliveryStatuses/DeliveryStatusesList/DeliveryStatusesList"
  )
);
const DeliveryStatusesCreate = React.lazy(() =>
  import(
    "Components/Orders/DeliveryStatuses/DeliveryStatusesCreate/DeliveryStatusesCreate"
  )
);
const DeliveryStatusesUpdate = React.lazy(() =>
  import(
    "Components/Orders/DeliveryStatuses/DeliveryStatusesUpdate/DeliveryStatusesUpdate"
  )
);

export const URL_NAME = "delivery-statuses";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <DeliveryStatusesList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <DeliveryStatusesCreate />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <DeliveryStatusesUpdate />
      </Suspense>
    )
  }
];

export default urls;
