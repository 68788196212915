import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const OffersList = React.lazy(() =>
  import("Components/MobileApp/Offers/OffersList/OffersList")
);
const OffersCreate = React.lazy(() =>
  import("Components/MobileApp/Offers/OffersCreate/OffersCreate")
);
const OffersUpdate = React.lazy(() =>
  import("Components/MobileApp/Offers/OffersUpdate/OffersUpdate")
);

export const URL_NAME = "app/offers";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <OffersList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <OffersCreate />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <OffersUpdate />
      </Suspense>
    )
  }
];

export default urls;
