import { FETCH_MENU, DELETE_MENU } from "../../actions/MenuDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const menuDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_MENU:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_MENU:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
