export class UserData {
  constructor(record = {}) {
    this._record = record;

    this.userId = record.userId;
    this.email = record.email;
    this.firstName = record.firstName;
    this.lastName = record.lastName;
    this.surName = record.surName;
    this.roles = record.roles;
  }
}
