import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const CommentUpdate = React.lazy(() =>
  import("../../Components/Comments/CommentUpdate/CommentUpdate")
);
const CommentsList = React.lazy(() =>
  import("../../Components/Comments/CommentsList/CommentsList")
);

const urls = [
  {
    path: "/comments",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CommentsList />
      </Suspense>
    )
  },
  {
    path: "/comments/:id",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CommentUpdate />
      </Suspense>
    )
  }
];

export default urls;
