import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const BlogList = React.lazy(() =>
  import("Components/Blogs/Blog/BlogList/BlogList")
);
const BlogUpdate = React.lazy(() =>
  import("Components/Blogs/Blog/BlogUpdate/BlogUpdate")
);
const BlogCreate = React.lazy(() =>
  import("Components/Blogs/Blog/BlogCreate/BlogCreate")
);

const urls = [
  {
    path: "/blog",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogList />
      </Suspense>
    )
  },
  {
    path: "/blog/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogCreate />
      </Suspense>
    )
  },
  {
    path: "/blog/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogUpdate />
      </Suspense>
    )
  }
];

export default urls;
