import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const StatusMappingList = React.lazy(() =>
  import("Components/Orders/StatusMapping/StatusMappingList/StatusMappingList")
);
const StatusMappingUpdate = React.lazy(() =>
  import(
    "Components/Orders/StatusMapping/StatusMappingUpdate/StatusMappingUpdate"
  )
);

export const URL_NAME = "status-mapping";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <StatusMappingList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <StatusMappingUpdate />
      </Suspense>
    )
  }
];

export default urls;
