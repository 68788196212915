import {
  FETCH_ALL_CHARACTERISTICS,
  FETCH_ALL_CHARACTERISTICS_VALUE,
  FETCH_ALL_CHARACTERISTICS_KEY
} from "../../actions/Сharacteristic/type";

const defaultState = {
  loaded: false,
  data: [],
  key: {
    loading: true
  },
  value: {
    loading: true
  }
};

export const characteristicReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_CHARACTERISTICS:
      return { ...state, data: action.payload, loaded: true };
    case FETCH_ALL_CHARACTERISTICS_KEY:
      return { ...state, key: { ...action.payload, loading: false } };
    case FETCH_ALL_CHARACTERISTICS_VALUE:
      return { ...state, value: { ...action.payload, loading: false } };
    default:
      return state;
  }
};
