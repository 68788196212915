class LocalStore {
  get(name) {
    if (typeof window !== "undefined") {
      return localStorage.getItem(name);
    }
    return undefined;
  }

  set(name, value) {
    if (typeof window !== "undefined") {
      return localStorage.setItem(name, value);
    }
    return undefined;
  }

  remove(name) {
    if (typeof window !== "undefined") {
      return localStorage.removeItem(name);
    }
    return undefined;
  }
}

const localStorageService = new LocalStore();

export { localStorageService };
