import { FETCH_MAIL } from "../../actions/MailDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const mailDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_MAIL:
      return { ...state, data: action.payload, loading: false, deleted: false };
    default:
      return state;
  }
};
