import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography
} from "@material-ui/core";
import { ShoppingBag as ShoppingBagIcon } from "react-feather";
import NavItem from "Components/NavItem/NavItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "@mui/material";

// https://github.com/devias-io/material-kit-react

const NavBar = props => {
  const { onMobileClose, user, items, logout } = props;

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src="/static/images/avatars/avatar_6.png"
          sx={{
            cursor: "pointer",
            width: 64,
            height: 64
          }}
          to="/app/account"
        ></Avatar>
        <Typography
          color="textPrimary"
          variant="h5"
          style={{ paddingTop: "5px" }}
        >
          {user.firstName} {user.lastName}
        </Typography>
        <Button onClick={logout} color="inherit">
          Выход
        </Button>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map(item => (
            <Accordion key={item.name} square={false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography>{item.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {item.children.map(item => (
                    <NavItem
                      key={item.name}
                      onClick={onMobileClose}
                      href={item.url}
                      title={item.name}
                      icon={ShoppingBagIcon}
                    />
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open
      variant="persistent"
      PaperProps={{
        style: {
          width: 256,
          top: 64,
          height: "calc(100% - 64px)"
        }
      }}
    >
      {content}
    </Drawer>
  );
};

export default NavBar;
