import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { productsReducer } from "./productsReducers";
import { productDetailReducer } from "./productDetailReducer";
import { characteristicReducer } from "./characteristic/characteristicReducer";
import { producersReducer } from "./producersReducer";
import { producerDetailReducer } from "./producerDetailReducer";
import { characteristicKeyDetailReducer } from "./characteristic/characteristicKeyDetailReducer";
import { characteristicValueDetailReducer } from "./characteristic/characteristicValueDetailReducer";
import { categoryReducer } from "./category/categoryReducer";
import { categoryDetailReducer } from "./category/categoryDetailReducer";
import { citiesReducer } from "./cities/citiesReducer";
import { citiesDetailReducer } from "./cities/сitiesDetailReducer";
import { districtsReducer } from "./districts/districtsReducer";
import { districtsDetailReducer } from "./districts/districtsDetailReducer";
import { marketsReducer } from "./markets/marketsReducer";
import { marketsDetailReducer } from "./markets/marketsDetailReducer";
import { usersDetailReducer } from "./users/usersDetailReducer";
import { usersReducer } from "./users/usersReducer";
import { menuReducer } from "./menu/menuReducer";
import { menuDetailReducer } from "./menu/menuDetailReducer";
import { bannerDetailReducer } from "./banners/bannerDetailReducer";
import { bannersReducer } from "./banners/bannersReducer";
import { commentsReducer } from "./comments/commentsReducer";
import { commentDetailReducer } from "./comments/commentDetailReducer";
import { mailsReducer } from "./mails/mailsReducer";
import { mailDetailReducer } from "./mails/mailDetailReducer";
import { upperBannersReducer } from "./upperBanners/upperBannersReducer";
import { upperBannerDetailReducer } from "./upperBanners/upperBannerDetailReducer";
import { tagListReducer, tagDetailReducer } from "./tags/tagsReducer";
import {
  relatedNamesListReducer,
  relatedNameDetailReducer
} from "./relatedNames/relatedNamesReducer";
import {
  relatedProductDetailReducer,
  relatedProductsListReducer
} from "./relatedProducts/relatedProductsReducer";

export default combineReducers({
  userReducer,
  relatedProductDetailReducer,
  relatedProductsListReducer,
  relatedNameDetailReducer,
  relatedNamesListReducer,
  tagListReducer,
  tagDetailReducer,
  productsReducer,
  productDetailReducer,
  characteristicReducer,
  producersReducer,
  producerDetailReducer,
  characteristicKeyDetailReducer,
  characteristicValueDetailReducer,
  categoryReducer,
  categoryDetailReducer,
  citiesReducer,
  citiesDetailReducer,
  districtsReducer,
  districtsDetailReducer,
  marketsReducer,
  marketsDetailReducer,
  usersReducer,
  usersDetailReducer,
  menuReducer,
  menuDetailReducer,
  bannerDetailReducer,
  bannersReducer,
  commentsReducer,
  commentDetailReducer,
  mailsReducer,
  mailDetailReducer,
  upperBannerDetailReducer,
  upperBannersReducer
});
