import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const AwardsList = React.lazy(() =>
  import("Components/Ratings/Awards/AwardsList/AwardsList")
);
const AwardsUpdate = React.lazy(() =>
  import("Components/Ratings/Awards/AwardsUpdate/AwardsUpdate")
);
const AwardsCreate = React.lazy(() =>
  import("Components/Ratings/Awards/AwardsCreate/AwardsCreate")
);

const urls = [
  {
    path: "/awards",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <AwardsList />
      </Suspense>
    )
  },
  {
    path: "/awards/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <AwardsCreate />
      </Suspense>
    )
  },
  {
    path: "/awards/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <AwardsUpdate />
      </Suspense>
    )
  }
];

export default urls;
