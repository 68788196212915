import React, { createContext, useEffect } from "react";
import { connect } from "react-redux";
import { login, checkLogin, logout } from "Stores/actions/User/action";

export const UserContext = createContext({});

const UserContextProvider = ({ value, children }) => (
  <UserContext.Provider value={value}>{children}</UserContext.Provider>
);

const UserProvider = props => {
  const { children, user, checkLogin, login, logout } = props;

  useEffect(() => {
    checkLogin();
  }, [0]);

  return (
    <UserContextProvider value={{ user, login, logout }}>
      {children}
    </UserContextProvider>
  );
};

const mapStateToProps = state => ({ user: state.userReducer });

const mapDispatchToProps = dispatch => ({
  login: (...args) => dispatch(login(...args)),
  checkLogin: (...args) => dispatch(checkLogin(...args)),
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProvider);
