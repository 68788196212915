import axios from "axios";
import {
  BASE_URL,
  TOKEN_NAME_LOCAL_STORE,
  REFRESH_TOKEN_NAME_LOCAL_STORE
} from "./constants";
import { localStorageService } from "./LocalStore";

axios.interceptors.request.use(config => {
  const token = localStorageService.get(TOKEN_NAME_LOCAL_STORE);
  if (!token) {
    localStorageService.remove(TOKEN_NAME_LOCAL_STORE);
  } else {
    config.headers.Authorization = token ? `Bearer ${token}` : null;
  }
  return config;
});

let prevErrorUrl = "";

axios.interceptors.response.use(
  response => response,
  async error =>
    new Promise((resolve, reject) => {
      if (
        error?.response?.status === 401 &&
        !prevErrorUrl.includes("/api/v1/admin/auth/update")
      ) {
        prevErrorUrl = error?.response.request.responseURL;
        setInterval(() => {
          prevErrorUrl = "";
        }, 5000);
        return updateAuthToken()
          .then(() => {
            const token = localStorageService.get(TOKEN_NAME_LOCAL_STORE);
            error.config.headers.Authorization = `Bearer ${token}`;
            return axios(error.config).then(resolve, reject);
          })
          .catch(error => {
            console.log("Error");
            reject(error);
          });
      }
      return reject(error);
    })
);

async function logOut() {
  const result = await axios.get(`${BASE_URL}admin/auth/logout`).then(() => {
    localStorageService.remove(TOKEN_NAME_LOCAL_STORE);
    localStorageService.remove(REFRESH_TOKEN_NAME_LOCAL_STORE);
  });
  return result;
}

async function updateAuthToken() {
  const result = await axios
    .post(`${BASE_URL}admin/auth/update`, {
      refreshToken: localStorageService.get(REFRESH_TOKEN_NAME_LOCAL_STORE)
    })
    .then(({ data }) => {
      localStorageService.set(TOKEN_NAME_LOCAL_STORE, data.accessToken);
      localStorageService.set(
        REFRESH_TOKEN_NAME_LOCAL_STORE,
        data.refreshToken
      );
      return Promise.resolve(true);
    })
    .catch(err => {
      localStorageService.remove(TOKEN_NAME_LOCAL_STORE);
      localStorageService.remove(REFRESH_TOKEN_NAME_LOCAL_STORE);
      throw err;
    });
  return result;
}

window.updateAuthToken = updateAuthToken;

class AuthService {
  _localAccessToken = TOKEN_NAME_LOCAL_STORE;

  async login(email, password, remember = false) {
    const result = await axios
      .post(`${BASE_URL}admin/auth/login`, { email, password })
      .then(({ data }) => {
        localStorageService.set(this._localAccessToken, data.accessToken);
        localStorageService.set(
          REFRESH_TOKEN_NAME_LOCAL_STORE,
          data.refreshToken
        );
        return true;
      })
      .catch(err => {
        localStorageService.remove(TOKEN_NAME_LOCAL_STORE);
        localStorageService.remove(REFRESH_TOKEN_NAME_LOCAL_STORE);
        throw err.response.data.message;
      });
    return result;
  }

  async logOut() {
    return logOut();
  }

  async getUserData() {
    const result = await axios
      .get(`${BASE_URL}auth/user`)
      .then(({ data }) => data);
    return result;
  }

  async getUserMenu() {
    const result = await axios
      .get(`${BASE_URL}admin/menu/`)
      .then(({ data }) => data);
    return result;
  }
}

const authService = new AuthService();

export { axios, authService };
