import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const RelatedProductsListContainer = React.lazy(() =>
  import("Container/RelatedProducts/RelatedProductsListContainer")
);
const RelatedProductsUpdateContainer = React.lazy(() =>
  import("Container/RelatedProducts/RelatedProductsUpdateContainer")
);
const RelatedProductsCreateContainer = React.lazy(() =>
  import("Container/RelatedProducts/RelatedProductsCreateContainer")
);

const urls = [
  {
    path: "/related-products",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RelatedProductsListContainer />
      </Suspense>
    )
  },
  {
    path: "/related-products/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RelatedProductsCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/related-products/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RelatedProductsUpdateContainer />
      </Suspense>
    )
  }
];

export default urls;
