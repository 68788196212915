import React, { useMemo, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loader from "Components/Loader/Loader";

const ProductsContainer = React.lazy(() =>
  import("Container/Product/ProductsContainer/ProductsContainer")
);
const ProductDetailContainer = React.lazy(() =>
  import("Container/Product/ProductDetailContainer/ProductDetailContainer")
);
const ProductCreateContainer = React.lazy(() =>
  import("Container/Product/ProductCreateContainer/ProductCreateContainer")
);
const ProducersContainer = React.lazy(() =>
  import("Container/Producer/ProducersContainer/ProducersContainer")
);
const ProducerDetailContainer = React.lazy(() =>
  import("Container/Producer/ProducerDetailContainer/ProducerDetailContainer")
);
const ProducerCreateContainer = React.lazy(() =>
  import("Container/Producer/ProducerCreateContainer/ProducerCreateContainer")
);
const CharacteristicKeyContainer = React.lazy(() =>
  import(
    "Container/Characteristic/CharacteristicKeyContainer/CharacteristicKeyContainer"
  )
);
const CharacteristicValueContainer = React.lazy(() =>
  import(
    "Container/Characteristic/CharacteristicValueContainer/CharacteristicValueContainer"
  )
);
const CharacteristicKeyDetailContainer = React.lazy(() =>
  import(
    "Container/Characteristic/CharacteristicKeyDetailContainer/CharacteristicKeyDetailContainer"
  )
);
const CharacteristicKeyCreateContainer = React.lazy(() =>
  import(
    "Container/Characteristic/CharacteristicKeyCreateContainer/CharacteristicKeyCreateContainer"
  )
);
const CharacteristicValueDetailContainer = React.lazy(() =>
  import(
    "Container/Characteristic/CharacteristicValueDetailContainer/CharacteristicValueDetailContainer"
  )
);
const CharacteristicValueCreateContainer = React.lazy(() =>
  import(
    "Container/Characteristic/CharacteristicValueCreateContainer/CharacteristicValueCreateContainer"
  )
);
const CategoryContainer = React.lazy(() =>
  import("Container/Categories/CategoryContainer/CategoryContainer")
);
const CategoryDetailContainer = React.lazy(() =>
  import("Container/Categories/CategoryDetailContainer/CategoryDetailContainer")
);
const CategoryCreateContainer = React.lazy(() =>
  import("Container/Categories/CategoryCreateContainer/CategoryCreateContainer")
);
const CitiesContainer = React.lazy(() =>
  import("Container/Cities/CitiesContainer/CitiesContainer")
);
const CitiesDetailContainer = React.lazy(() =>
  import("Container/Cities/CitiesDetailContainer/CitiesDetailContainer")
);
const CitiesCreateContainer = React.lazy(() =>
  import("Container/Cities/CitiesCreateContainer/CitiesCreateContainer")
);
const DistrictsContainer = React.lazy(() =>
  import("Container/Districts/DistrictsContainer/DistrictsContainer")
);
const DistrictsDetailContainer = React.lazy(() =>
  import(
    "Container/Districts/DictrictsDetailContainer/DictrictsDetailContainer"
  )
);
const DistrictsCreateContainer = React.lazy(() =>
  import(
    "Container/Districts/DistrictsCreateContainer/DistrictsCreateContainer"
  )
);
const MarketsContainer = React.lazy(() =>
  import("Container/Markets/MarketsContainer/MarketsContainer")
);
const MarketsDetailContainer = React.lazy(() =>
  import("Container/Markets/MarketsDetailContainer/MarketsDetailContainer")
);
const MarketsCreateContainer = React.lazy(() =>
  import("Container/Markets/MarketsCreateContainer/MarketsCreateContainer")
);
const UsersContainer = React.lazy(() =>
  import("Container/Users/UsersContainer/UsersContainer")
);
const UsersDetailContainer = React.lazy(() =>
  import("Container/Users/UsersDetailContainer/UsersDetailContainer")
);
const MenuContainer = React.lazy(() =>
  import("Container/Menu/MenuContainer/MenuContainer")
);
const MenuDetailContainer = React.lazy(() =>
  import("Container/Menu/MenuDetailContainer/MenuDetailContainer")
);
const MenuCreateContainer = React.lazy(() =>
  import("Container/Menu/MenuCreateContainer/MenuCreateContainer")
);
const MailsContainer = React.lazy(() =>
  import("Container/Mails/MailsContainer/MailsContainer")
);
const MailDetailContainer = React.lazy(() =>
  import("Container/Mails/MailDetailContainer/MailDetailContainer")
);
const MailCreateContainer = React.lazy(() =>
  import("Container/Mails/MailCreateContainer/MailCreateContainer")
);
const UpperBannersContainer = React.lazy(() =>
  import("Container/UpperBanners/UpperBannersContainer")
);
const UpperBannerDetailContainer = React.lazy(() =>
  import("Container/UpperBanners/UpperBannerDetailContainer")
);
const UpperBannerCreateContainer = React.lazy(() =>
  import("Container/UpperBanners/UpperBannersCreateContainer")
);
import tags from "./tags/index";
import relatedNames from "./relatedNames/index";
import relatedProducts from "./relatedProducts";
import comments from "./comments";
import xml from "./xml";
import schedule from "./schedule";
import blogStyle from "./blogStyle";
import blogCategory from "./blogCategory";
import blog from "./blog";
import images from "./images";
import ingredients from "./ingredients";
import recipes from "./recipes";
import promoCodes from "./promoCode";
import newBanners from "./banners";
import awards from "./awards";
import ratings from "./ratings";
import orders from "./orders";
import mobileApp from "./mobileApp";

const MockComponent = ({ text }) => <h2>{text || "JUST TEXT"}</h2>;

const routesData = [
  {
    path: "/product",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ProductsContainer />
      </Suspense>
    )
  },
  {
    path: "/product/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ProductCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/product/:id/",
    component: (
      <Suspense fallback={<Loader />}>
        <ProductDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/characteristic_key",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CharacteristicKeyContainer />
      </Suspense>
    )
  },
  {
    path: "/characteristic_key/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CharacteristicKeyCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/characteristic_key/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <CharacteristicKeyDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/characteristic_value",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CharacteristicValueContainer />
      </Suspense>
    )
  },
  {
    path: "/characteristic_value/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CharacteristicValueCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/characteristic_value/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <CharacteristicValueDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/category",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CategoryContainer />
      </Suspense>
    )
  },
  {
    path: "/category/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CategoryCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/category/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <CategoryDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/producer",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ProducersContainer />
      </Suspense>
    )
  },
  {
    path: "/producer/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ProducerCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/producer/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <ProducerDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/cities",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CitiesContainer />
      </Suspense>
    )
  },
  {
    path: "/cities/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CitiesCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/cities/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <CitiesDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/districts",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <DistrictsContainer />
      </Suspense>
    )
  },
  {
    path: "/districts/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <DistrictsCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/districts/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <DistrictsDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/market",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MarketsContainer />
      </Suspense>
    )
  },
  {
    path: "/market/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MarketsCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/market/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <MarketsDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/users",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UsersContainer />
      </Suspense>
    )
  },
  {
    path: "/users/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <UsersDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/prod-menu",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MenuContainer />
      </Suspense>
    )
  },
  {
    path: "/prod-menu/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MenuCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/prod-menu/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <MenuDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/admin-mails",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MailsContainer />
      </Suspense>
    )
  },
  {
    path: "/admin-mails/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MailCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/admin-mails/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MailDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/upper-banners",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UpperBannersContainer />
      </Suspense>
    )
  },
  {
    path: "/upper-banners/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UpperBannerCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/upper-banners/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UpperBannerDetailContainer />
      </Suspense>
    )
  },
  ...tags,
  ...relatedNames,
  ...relatedProducts,
  ...comments,
  ...xml,
  ...schedule,
  ...blogStyle,
  ...blogCategory,
  ...blog,
  ...images,
  ...ingredients,
  ...recipes,
  ...promoCodes,
  ...newBanners,
  ...awards,
  ...ratings,
  ...orders,
  ...mobileApp
];

const Pages = ({ menu }) => {
  const newPages = useMemo(() =>
    routesData.filter(item => {
      const pureURL = item.path.replaceAll(":id", "").replaceAll("/", "");
      const has = menu.some(selectd =>
        selectd.children.some(item2 => item2.url.includes(pureURL))
      );
      return { ...has, exact: item.exact };
    })
  );

  return (
    <Switch>
      <Route path="/" exact render={() => <MockComponent text="JUST PAGE" />} />
      {newPages.map(item => (
        <Route
          exact={!!item.exact}
          key={item.path}
          path={item.path}
          render={() => item.component}
        />
      ))}
      <Route path="/404" render={() => <MockComponent text="404" />} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Pages;
