import { FETCH_TAG, DELETE_TAG, FETCH_ALL_TAGS } from "../../actions/Tags/type";

const defaultDetailState = {
  loading: true,
  data: {}
};

const defaultListState = {
  loading: true,
  data: {
    count: 20,
    maxPage: 1,
    page: 1,
    data: []
  }
};

export const tagDetailReducer = (state = defaultDetailState, action) => {
  switch (action.type) {
    case FETCH_TAG:
      return { ...state, data: action.payload, loading: false };
    case DELETE_TAG:
      return { ...state, data: {} };
    default:
      return state;
  }
};

export const tagListReducer = (state = defaultListState, action) => {
  switch (action.type) {
    case FETCH_ALL_TAGS:
      return { ...state, data: action.payload, loading: false };
    default:
      return state;
  }
};
