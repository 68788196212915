import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const RelatedNamesListContainer = React.lazy(() =>
  import("Container/RelatedNames/RelatedNamesListContainer")
);
const RelatedNameUpdateContainer = React.lazy(() =>
  import("Container/RelatedNames/RelatedNameUpdateContainer")
);
const RelatedNameCreateContainer = React.lazy(() =>
  import("Container/RelatedNames/RelatedNameCreateContainer")
);

const urls = [
  {
    path: "/related-names",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RelatedNamesListContainer />
      </Suspense>
    )
  },
  {
    path: "/related-names/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RelatedNameCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/related-names/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RelatedNameUpdateContainer />
      </Suspense>
    )
  }
];

export default urls;
