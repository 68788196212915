import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const RatingsList = React.lazy(() =>
  import("Components/Ratings/Ratings/RatingsList/RatingsList")
);
const RatingsCreate = React.lazy(() =>
  import("Components/Ratings/Ratings/RatingsCreate/RatingsCreate")
);
const RatingsUpdate = React.lazy(() =>
  import("Components/Ratings/Ratings/RatingsUpdate/RatingsUpdate")
);

const urls = [
  {
    path: "/ratings",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RatingsList />
      </Suspense>
    )
  },
  {
    path: "/ratings/create",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RatingsCreate />
      </Suspense>
    )
  },
  {
    path: "/ratings/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RatingsUpdate />
      </Suspense>
    )
  }
];

export default urls;
