import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const ScheduleList = React.lazy(() =>
  import("../../Components/Schedule/ScheduleList/ScheduleList")
);
const ScheduleUpdate = React.lazy(() =>
  import("../../Components/Schedule/ScheduleUpdate/ScheduleUpdate")
);

const urls = [
  {
    path: "/schedule",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ScheduleList />
      </Suspense>
    )
  },
  {
    path: "/schedule/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ScheduleUpdate />
      </Suspense>
    )
  }
];

export default urls;
