import React, { useState } from "react";
import NavBar from "Components/NavBar/NavBar";
import DashboardNavbar from "Components/DashboardNavbar/DashboardNavbar";
import {
  DashboardLayoutContainer,
  DashboardLayoutContent,
  DashboardLayoutRoot,
  DashboardLayoutWrapper
} from "./styled";

const DashboardLayout = ({ items, children, user, logout }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        user={user}
        items={items}
        logout={logout}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>{children}</DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
