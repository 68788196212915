import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import App from "Components/App/App";
import { store } from "Stores";
require("dotenv").config();

window.onload = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Switch>
          <App />
        </Switch>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
};
