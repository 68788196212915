import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const OrderStatusesList = React.lazy(() =>
  import("Components/Orders/OrderStatuses/OrderStatusesList/OrderStatusesList")
);
const OrderStatusesCreate = React.lazy(() =>
  import(
    "Components/Orders/OrderStatuses/OrderStatusesCreate/OrderStatusesCreate"
  )
);
const OrderStatusesUpdate = React.lazy(() =>
  import(
    "Components/Orders/OrderStatuses/OrderStatusesUpdate/OrderStatusesUpdate"
  )
);

export const URL_NAME = "order-statuses";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <OrderStatusesList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <OrderStatusesCreate />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <OrderStatusesUpdate />
      </Suspense>
    )
  }
];

export default urls;
