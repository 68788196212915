import { authService } from "../../../Services/Api.auth";
import {
  USER_SUCCESS_MENU,
  USER_SUCCESS_DATA,
  USER_FAILED_LOGIN,
  USER_LOADER,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "./type";
import { UserData } from "../../records/UserData";
import { UserMenu } from "../../records/UserMenu";

const loaderLogin = (dispatch, loader) =>
  dispatch({ type: USER_LOADER, loader });

const checkLogin = () => async (dispatch, getState) => {
  loaderLogin(dispatch, true);

  await authService
    .getUserData()
    .then(data => {
      if (!data.user) {
        throw "NOT USER DATA";
      }
      dispatch({ type: USER_SUCCESS_DATA, payload: new UserData(data.user) });
    })
    .then(authService.getUserMenu)
    .then(data => {
      dispatch({
        type: USER_SUCCESS_MENU,
        payload: data.data.map(item => new UserMenu(item))
      });
    })
    .catch(err => {
      throw err || "NOT USER DATA";
    })
    .finally(() => {
      const { isLoader, userId, menu } = getState().userReducer;
      if (!isLoader || userId || menu.length >= 1) {
        dispatch({ type: USER_LOGIN_SUCCESS });
      }
      loaderLogin(dispatch, false);
    });
};

const login = (email, password) => async (dispatch, getState) => {
  loaderLogin(dispatch, true);

  await authService.login(email, password).catch(async err => {
    dispatch({ type: USER_FAILED_LOGIN, message: err });
  });

  await checkLogin()(dispatch, getState);
};

const logout = () => async dispatch => {
  await authService.logOut().then(() => {
    dispatch({ type: USER_LOGOUT });
    window.location.href = window.location.origin + "/login";
  });
};

export { login, checkLogin, logout };
