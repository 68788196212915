import { IMAGES_SECRET_KEY, IMAGES_URL } from "./constants";

class ServiceImages {
  async addImage(image_path, image_uuid, data, image_quality) {
    const url = `${IMAGES_URL}/compress/image?image_path=${image_path}&image_uuid=${image_uuid}&api-key=${IMAGES_SECRET_KEY}${
      image_quality ? `&image_quality=` + image_quality : ""
    }`;
    const options = {
      method: "POST",
      body: data
    };
    const result = await fetch(url, options)
      .then(response => response.json())
      .catch(error => {});
    return result?.data;
  }

  async deleteImage(image_path, image_uuid, image_name) {
    const url = `${IMAGES_URL}/compress/image?image_path=${image_path}&image_uuid=${image_uuid}&api-key=${IMAGES_SECRET_KEY}&image_name=${image_name}`;
    const options = {
      method: "DELETE"
    };
    const result = await fetch(url, options)
      .then(response => response.json())
      .catch(error => {});
    return result?.data;
  }
}

const apiImages = new ServiceImages();

export { apiImages };
