import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Button, ListItem } from "@material-ui/core";

const NavItem = ({ href, icon: Icon, title, ...rest }) => {
  return (
    <ListItem disableGutters {...rest} style={{ background: "#fff" }}>
      <Button component={RouterLink} to={href}>
        {Icon && <Icon size="20" />}
        <span style={{ fontSize: 12, marginLeft: "10px" }}>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
