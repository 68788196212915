import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const Xml = React.lazy(() => import("../../Components/Xml/Xml"));

const urls = [
  {
    path: "/xml",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Xml />
      </Suspense>
    )
  }
];

export default urls;
