import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const RecipesList = React.lazy(() =>
  import("../../Components/Mixology/Recipes/RecipesList/RecipesList")
);
const RecipesCreate = React.lazy(() =>
  import("../../Components/Mixology/Recipes/RecipesCreate/RecipesCreate")
);
const RecipesUpdate = React.lazy(() =>
  import("../../Components/Mixology/Recipes/RecipesUpdate/RecipesUpdate")
);

const urls = [
  {
    path: "/recipes",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RecipesList />
      </Suspense>
    )
  },
  {
    path: "/recipes/create",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RecipesCreate />
      </Suspense>
    )
  },
  {
    path: "/recipes/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <RecipesUpdate />
      </Suspense>
    )
  }
];

export default urls;
