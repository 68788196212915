import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

function ModalLoader(props) {
  const { open } = props;
  return (
    <Backdrop
      style={{ color: "#fff", zIndex: 100000 }}
      open={open}
      onClick={() => {}}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default ModalLoader;
