import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const BlogCategoryList = React.lazy(() =>
  import("Components/Blogs/BlogCategory/BlogCategoryList/BlogCategoryList")
);
const BlogCategoryUpdate = React.lazy(() =>
  import("Components/Blogs/BlogCategory/BlogCategoryUpdate/BlogCategoryUpdate")
);
const BlogCategoryCreate = React.lazy(() =>
  import("Components/Blogs/BlogCategory/BlogCategoryCreate/BlogCategoryCreate")
);

const urls = [
  {
    path: "/blog-category",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogCategoryList />
      </Suspense>
    )
  },
  {
    path: "/blog-category/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogCategoryCreate />
      </Suspense>
    )
  },
  {
    path: "/blog-category/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogCategoryUpdate />
      </Suspense>
    )
  }
];

export default urls;
