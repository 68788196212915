import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// eslint-disable-next-line react/display-name
const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function Notification(props) {
  const { open, setOpen, errorMessage } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={errorMessage ? "error" : "success"}
        sx={{ width: "100%" }}
      >
        {errorMessage || "Зміни збережено"}
      </Alert>
    </Snackbar>
  );
}
