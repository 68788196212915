import { FETCH_ALL_PRODUCTS } from "../actions/Products/type";

const defaultState = {
  loading: true,
  data: {
    count: 20,
    maxPage: 1,
    page: 1,
    data: []
  }
};

export const productsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_PRODUCTS:
      return { ...state, data: action.payload, loading: false };
    default:
      return state;
  }
};
