import { FETCH_CITY, DELETE_CITY } from "../../actions/CitiesDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const citiesDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CITY:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_CITY:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
