import { FETCH_USER, DELETE_USER } from "../../actions/UsersDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const usersDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_USER:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
