import {
  FETCH_PRODUCER,
  DELETE_PRODUCER
} from "../actions/ProducerDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const producerDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PRODUCER:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_PRODUCER:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
