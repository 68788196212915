import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const IngredientsList = React.lazy(() =>
  import("Components/Mixology/Ingredients/IngredientsList/IngredientsList")
);
const IngredientsCreate = React.lazy(() =>
  import(
    "../../Components/Mixology/Ingredients/IngredientsCreate/IngredientsCreate"
  )
);
const IngredientsUpdate = React.lazy(() =>
  import(
    "../../Components/Mixology/Ingredients/IngredientsUpdate/IngredientsUpdate"
  )
);

const urls = [
  {
    path: "/ingredients",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <IngredientsList />
      </Suspense>
    )
  },
  {
    path: "/ingredients/create",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <IngredientsCreate />
      </Suspense>
    )
  },
  {
    path: "/ingredients/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <IngredientsUpdate />
      </Suspense>
    )
  }
];

export default urls;
