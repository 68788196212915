import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import UserHOC from "HOC/UserHOC";
import { UserContext } from "Providers/UserProvider/UserProvider";
import NotificationProvider from "../../Providers/NotificationProvider/NotificationProvider";
import Login from "Components/Login/Login";
import DashboardLayout from "Components/DashboardLayout/DashboardLayout";
import Loader from "Components/Loader/Loader";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "CSS/globalStyle";
import theme from "CSS/theme";
import Pages from "Pages/Pages";
import "../../CSS/style.css";
import ImagesProvider from "../../Providers/ImagesProvider/ImagesProvider";

const App = () => {
  const router = useHistory();
  const { user, login, logout } = useContext(UserContext);

  const { isLogin, isLoader, menu } = user;

  if (!isLogin && !isLoader && !router.location.pathname.includes("login")) {
    router.push("/login");
  }

  if (isLoader) {
    return <Loader />;
  }

  if (!isLogin) {
    return (
      <Route exact path="/login">
        <Login fetchLogin={login} />
      </Route>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <DashboardLayout items={menu} user={user} logout={logout}>
        <div>
          <NotificationProvider>
            <ImagesProvider>
              <Pages menu={menu} />
            </ImagesProvider>
          </NotificationProvider>
        </div>
      </DashboardLayout>
    </ThemeProvider>
  );
};

export default UserHOC(App);
