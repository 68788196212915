import {
  FETCH_COMMENT,
  DELETE_COMMENT
} from "../../actions/CommentDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const commentDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_COMMENT:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_COMMENT:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
