export const BASE_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_DEV
    : process.env.REACT_APP_BASE_API_PROD;

export const ORDERS_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_PURCHASE_DEV
    : process.env.REACT_APP_BASE_API_PURCHASE_PROD;

export const COMMENTS_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_COMMENT_DEV
    : process.env.REACT_APP_BASE_API_COMMENT_PROD;

export const REVIEWS_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_REVIEW_DEV
    : process.env.REACT_APP_BASE_API_REVIEW_PROD;

export const URL_1C =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_1C_DEV
    : process.env.REACT_APP_BASE_API_1C_PROD;

export const IMAGES_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_IMAGES_DEV
    : process.env.REACT_APP_BASE_API_IMAGES_PROD;

export const IMAGES_SECRET_KEY =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_IMAGES_SECRET_KEY_DEV
    : process.env.REACT_APP_IMAGES_SECRET_KEY_PROD;

export const MIXOLOGY_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_MIXOLOGY_API_DEV
    : process.env.REACT_APP_MIXOLOGY_API_PROD;

export const PROMO_CODE_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_PROMO_CODE_DEV
    : process.env.REACT_APP_BASE_API_PROMO_CODE_PROD;

export const MARKETING_URL =
    process.env.REACT_APP_ENVIRONMENT === "DEV"
        ? process.env.REACT_APP_BASE_API_MARKETING_DEV
        : process.env.REACT_APP_BASE_API_MARKETING_PROD;

export const BENEFIT_URL =
  process.env.REACT_APP_ENVIRONMENT === "DEV"
    ? process.env.REACT_APP_BASE_API_BENEFIT_DEV
    : process.env.REACT_APP_BASE_API_BENEFIT_PROD;

export const TOKEN_NAME_LOCAL_STORE = "tokenAdmin";
export const REFRESH_TOKEN_NAME_LOCAL_STORE = "URP";
