import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const CategoriesList = React.lazy(() =>
  import("Components/MobileApp/Categories/CategoriesList/CategoriesList")
);
const CategoriesCreate = React.lazy(() =>
  import("Components/MobileApp/Categories/CategoriesCreate/CategoriesCreate")
);
const CategoriesUpdate = React.lazy(() =>
  import("Components/MobileApp/Categories/CategoriesUpdate/CategoriesUpdate")
);

export const URL_NAME = "app/categories";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CategoriesList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CategoriesCreate />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CategoriesUpdate />
      </Suspense>
    )
  }
];

export default urls;
