import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const BlogStyleList = React.lazy(() =>
  import("Components/Blogs/BlogStyle/BlogStyleList/BlogStyleList")
);
const BlogStyleUpdate = React.lazy(() =>
  import("Components/Blogs/BlogStyle/BlogStyleUpdate/BlogStyleUpdate")
);
const BlogStyleCreate = React.lazy(() =>
  import("Components/Blogs/BlogStyle/BlogStyleCreate/BlogStyleCreate")
);

const urls = [
  {
    path: "/blog-style",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogStyleList />
      </Suspense>
    )
  },
  {
    path: "/blog-style/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogStyleCreate />
      </Suspense>
    )
  },
  {
    path: "/blog-style/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <BlogStyleUpdate />
      </Suspense>
    )
  }
];

export default urls;
