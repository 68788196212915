import {
  USER_SUCCESS_MENU,
  USER_SUCCESS_DATA,
  USER_FAILED_LOGIN,
  USER_LOADER,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "../actions/User/type";

const initialState = {
  isLogin: false,
  isLoader: true,
  roles: [],
  menu: [],
  userId: "",
  email: "",
  firstName: "",
  lastName: "",
  surName: "",
  errorMsg: ""
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initialState, isLoader: false, isLogin: false };
    case USER_SUCCESS_MENU:
      return { ...state, menu: action.payload };
    case USER_SUCCESS_DATA:
      return { ...state, ...action.payload };
    case USER_FAILED_LOGIN:
      return { ...state, errorMsg: action.message };
    case USER_LOADER:
      return { ...state, isLoader: action.loader };
    case USER_LOGIN_SUCCESS:
      return { ...state, isLogin: true };
    default:
      return state;
  }
};
