import { FETCH_BANNER, DELETE_BANNER } from "../../actions/BannersDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const bannerDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_BANNER:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_BANNER:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
