import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const PromoCodeList = React.lazy(() =>
  import("../../Components/PromoCode/PromoCodeList/PromoCodeList")
);
const PromoCodeCreate = React.lazy(() =>
  import("../../Components/PromoCode/PromoCodeCreate/PromoCodeCreate")
);
const PromoCodeUpdate = React.lazy(() =>
  import("../../Components/PromoCode/PromoCodeUpdate/PromoCodeUpdate")
);

const urls = [
  {
    path: "/promo-code",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <PromoCodeList />
      </Suspense>
    )
  },
  {
    path: "/promo-code/create",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <PromoCodeCreate />
      </Suspense>
    )
  },
  {
    path: "/promo-code/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <PromoCodeUpdate />
      </Suspense>
    )
  }
];

export default urls;
