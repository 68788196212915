import { FETCH_MARKET, DELETE_MARKET } from "../../actions/MarketsDetail/type";

const defaultState = {
  loading: true,
  deleted: false,
  data: {}
};

export const marketsDetailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_MARKET:
      return { ...state, data: action.payload, loading: false, deleted: false };
    case DELETE_MARKET:
      return { ...state, data: action.payload, deleted: true };
    default:
      return state;
  }
};
