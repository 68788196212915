import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const TagsListContainer = React.lazy(() =>
  import("Container/TagsContainer/TagsListContainer")
);
const TagsUpdateContainer = React.lazy(() =>
  import("Container/TagsContainer/TagUpdateContainer")
);
const TagsCreateContainer = React.lazy(() =>
  import("Container/TagsContainer/TagCreateContainer")
);

const urls = [
  {
    path: "/tags",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <TagsListContainer />
      </Suspense>
    )
  },
  {
    path: "/tags/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <TagsCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/tags/:id/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <TagsUpdateContainer />
      </Suspense>
    )
  }
];

export default urls;
